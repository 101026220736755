<template>
    <div>
        <h3 class="col-md-12 text-dark font-weight-bold my-2 mr-5" v-if="!index">Thông tin công việc</h3>
        <h3 class="col-md-12 text-dark font-weight-bold my-2 mr-5" v-if="index == 1">Vị trí kiêm nhiệm</h3>
        <div class="row col-md-12 mb-4 mt-4">
            <div class="col-md-11 row border rounded p-2 ml-6">
                <div class="col-md-4" >
                    <div class="form-group">
                        <label>Khối<span class="text-danger">*</span></label>
                        <ValidationProvider vid="block_id" name="Khối"
                                            :rules="{ required: true }"
                                            v-slot="{ errors,classes }">
                            <el-select filterable class="w-100" placeholder="Khối"
                                       v-model="data.block_id"
                                       clearable
                                       @input="getListDepartment(data.block_id)"
                            >
                                <el-option
                                    v-for="item in listBlock"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0]
                                    }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4 " v-if="!checkShowDepartment">
                    <div class="form-group">
                        <label>Vị trí
                            <span class="text-danger">*</span></label>
                        <ValidationProvider :vid="'user_profile_account_type.' + index +'.department_id_prop'" name="Vị trí"
                                            :rules="{ required: true }"
                                            v-slot="{ errors,classes }">
                            <el-select filterable class="w-100" placeholder="Vị trí"
                                       v-model="data.account_type_id"
                                       clearable
                                       @input="handleAccountType"
                            >
                                <el-option
                                    v-for="item in accountType"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4" v-if="!checkShowDepartment">
                    <div class="form-group">
                        <label>Khu vực làm việc<span class="text-danger">*</span></label>
                        <ValidationProvider vid="data.khuvuc_id" name="Khu vực làm việc"
                                            rules="required"
                                            v-slot="{ errors,classes }">
                            <el-select filterable class="w-100"
                                       placeholder="Khu vực làm việc"
                                       v-model="data.khuvuc_id"
                                       clearable
                                       @input="updateStateKhuvuc"
                            >
                                <el-option
                                    v-for="item in areas"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0]
                                    }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4" v-if="!checkShowDepartment">
                    <div class="form-group">
                        <label>Vùng<span class="text-danger">*</span></label>
                        <ValidationProvider vid="data.khuvuc_id" name="Vùng"
                                            rules="required"
                                            v-slot="{ errors,classes }">
                            <el-select filterable class="w-100"
                                       placeholder="Vùng"
                                       v-model="data.region_id"
                                       clearable
                            >
                                <el-option
                                    v-for="item in region"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0]
                                    }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4" v-if="!checkShowDepartment">
                    <div class="form-group">
                        <label>Văn phòng làm việc<span class="text-danger"></span></label>
                        <ValidationProvider vid="chi_nhanh_id" name="Chọn văn phòng"
                                            :rules="{ required: false }"
                                            v-slot="{ errors,classes }">
                            <el-select filterable class="w-100" placeholder="Chọn văn phòng"
                                       v-model="data.chi_nhanh_id"
                                       clearable
                            >
                                <el-option
                                    v-for="item in branches"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0]
                                    }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4" v-if="!checkShowDepartment">
                    <div class="form-group">
                        <label class="form-label">Cơ chế thu nhập<span class="text-danger">*</span></label>
                        <validation-provider vid="area_id" name="Cơ chế thu nhập" rules="required"
                                             v-slot="{ errors, classes}">
                            <el-select v-model="data.salary_mechanism_id"
                                       class="w-100"
                                       clearable filterable
                                       placeholder="Chọn cơ chế thu nhập"
                                       :class="classes"
                            >
                                <el-option
                                    v-for="item in mechanisms"
                                    :key="item.id"
                                    :label="item.name "
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                                {{ errors[0] }}
                            </div>
                        </validation-provider>
                    </div>
                </div>
                <div class="col-md-4" v-if="checkShowDepartment">
                    <div class="form-group">
                        <label>Phòng ban</label>
                        <ValidationProvider vid="department_id" name="Phòng ban"
                                            :rules="{ required: false }"
                                            v-slot="{ errors,classes }">
                            <el-select filterable class="w-100" placeholder="Phòng ban"
                                       v-model="data.department_id"
                                       clearable
                                       @input="getListPart(data.department_id, true)"
                            >
                                <el-option
                                    v-for="item in departments"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0]
                                    }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4" v-if="checkShowDepartment">
                    <div class="form-group">
                        <label>Bộ phận</label>
                        <ValidationProvider vid="part_id" name="Bộ phận"
                                            :rules="{ required: false }"
                                            v-slot="{ errors,classes }">
                            <el-select filterable class="w-100" placeholder="Bộ phận"
                                       v-model="data.part_id"
                                       clearable
                            >
                                <el-option
                                    v-for="item in part"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0]
                                    }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4 " v-if="checkShowDepartment">
                    <div class="form-group">
                        <label>Vị trí
                            <span class="text-danger">*</span></label>
                        <ValidationProvider :vid="'user_profile_account_type.' + index +'.department_id_prop'" name="Vị trí"
                                            :rules="{ required: true }"
                                            v-slot="{ errors,classes }">
                            <el-select filterable class="w-100" placeholder="Vị trí"
                                       v-model="data.account_type_id"
                                       clearable
                                       @input="handleAccountType"
                            >
                                <el-option
                                    v-for="item in accountType"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4 " v-if="!checkShowDepartment">
                    <div class="form-group">
                        <label>Chức vụ kiêm nhiệm
                            <span class="text-danger"></span></label>
                        <multiselect v-model="data.cachePosition" :options="positions"
                                     placeholder="Chọn chức vụ"
                                     label="position"
                                     track-by="position"
                                     :multiple="true"
                                     @input="updateStatePositions"
                        ></multiselect>
                    </div>
                </div>
                <!-- v-if="showSelectBoss" -->
                <div class="col-md-4" v-if="!checkShowDepartment">
                    <div class="form-group">
                        <label>Chọn cấp trên</label>
                        <ValidationProvider vid="phone" name="Nhập mã cấp trên..."
                                            :rules="{  }"
                                            v-slot="{ errors,classes }">
                            <el-select
                                class="w-100"
                                v-model="parentName"
                                filterable
                                clearable   
                                remote
                                reserve-keyword
                                @change="changeParent"
                                placeholder="Nhập mã cấp trên..."
                                :remote-method="searchUserByBossCode"
                                :loading="search_loading">
                                <el-option
                                    v-for="item in users"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    <span style="float: left">{{ item?.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0]
                                    }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Ngày bắt đầu làm việc<span class="text-danger">*</span></label>
                        <ValidationProvider :vid="'user_profile_account_type.' + index +'.start_to_work'"
                                            name="Ngày bắt đầu làm việc"
                                            :rules="{ required: true}"
                                            v-slot="{ errors,classes }">
                            <date-picker name="start_to_work" v-model="data.start_to_work" format="DD-MM-YYYY" placeholder="Chọn ngày"
                                         valueType="YYYY-MM-DD" :class="classes"></date-picker>
                            <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4 pt-10 d-flex">
                    <div>
                        <input type="checkbox" v-model="data.captain" :disabled="data.captain" value="true" @input="checkCaption"/>
                        <span class="pl-2">Đặt làm công việc chính</span>
                    </div>

                    <div class="ml-4">
                        <input type="checkbox" v-model="data.is_collaborators"  value="true" @input="checkCaption"/>
                        <span class="pl-2">Cộng tác viên</span>
                    </div>

                </div>
            </div>
            <div class="pl-10 grid text-center">
                <div></div>
                <div class="h-30">
                    <a v-if="index" title="Xóa" @click="removeForm"
                       href="javascript:"
                       class="btn btn-sm btn-icon btn-outline-danger"><i class="el-icon-delete-solid"></i>
                    </a>
                </div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import {GET_DEPARTMENT, GET_ROLE_DEPARTMENT_BY_TYPE, BLOCK, GDVH, GDCL, GDDH, DkD} from "../../../../core/services/store/department/department.module";
import {GET_ACCOUNT_TYPE, GET_POSITIONS} from "../../../../core/services/store/user/account-type.module";
import {mechanisms, mechanisms2} from "../../../../core/option/mechanism";
import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect'
import {GET_BRANCHES} from "../../../../core/services/store/center/branch.module";
import { BOD, TEAMLEADER, SALE_LEADER, SALE_EXPERT, SALE_MEMBER, SALE_INTERN, GDVP, GDKV, BRANCH,
    HANH_CHINH_KHU_VUC, QLHV, CENTER_ASSIGN, STAFF} from "../../../../core/option/accountTypeOption";
import {GET_BUSINESS_LEVEL} from "../../../../core/services/store/business/level.module";
import {SEARCH_BY_MA_CAP_TREN} from "../../../../core/services/store/user/users.module";
import {GET_AREAS} from "../../../../core/services/store/center/area.module";
import {GET_REGION} from "../../../../core/services/store/center/region.module";
export default {
    name: "UserItem",
    props: {
        index: {
            type: Number,
            default: 0
        },
        data: {
            type: Object,
            default: {}
        },
        parent: {
            type: Object,
            default: {}
        }
    },
    components: {
        DatePicker,
        Multiselect
    },
    data() {
        return {
            departments: {},
            part: {},
            checkShowDepartment: false,
            accountType: [],
            positions: [],
            showSelectBoss: false,
            is_show_branch: false,
            showTrungTam: false,
            checkShow: false,
            businessLevels: [],
            is_show_mechanism: false,
            mechanisms: mechanisms,
            branches: [],
            listBlock: {},
            search_loading: false,
            areas: [],
            users: [],
            parentName: '',
            show: false,
            array: [],
            gdvh: GDVH,
            gdcl: GDCL,
            gddh: GDDH,
            dkd: DkD,
            listMechanisms: mechanisms,
            listMechanisms2: mechanisms2,
            mechanismsDefine: {
                1:'Lương cứng',
                2:'Lương mềm',
                3:'Lương cứng theo bậc',
                4:'Lương cứng cố định',
                5:'Lương cứng partime'
            },
            region: {}
        }
    },
    mounted() {
        this.getBranches();
        this.getPositions();
        this.getBlock();
        this.getAreas();
        this.checkDepartment(this.data.block_id);
        this.getListPart(this.data.department_id, false);
        this.getMechanisms();
        this.getRegion();
        this.parentHandle();
    },
    methods: {
        getRegion() {
            this.$store.dispatch(GET_REGION, {
                limit: 10000,
                status: 1,
            }).then((res) => {
                if (!res.error) {
                    console.log(res.data, 'a')
                    this.region = res.data
                }
            });
        },
        checkDepartment(block_id) {
            if (block_id != this.dkd) {
                this.checkShowDepartment = true;
            } else  {
                this.checkShowDepartment = false;
            }
            this.$store.dispatch(GET_DEPARTMENT,{parent_id: block_id}).then((res) => {
                if (!res.error) {
                  this.departments = res.data
                  this.getAccountType(block_id);
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getAccountType(block_id) {
            this.$store.dispatch(GET_ACCOUNT_TYPE, {
                type_department: block_id != this.dkd ? 1 : 2,
                limit: 1000
            }).then((res) => {
                if (!res.error) {
                    this.accountType = res.data.data
                }
            });
        },
        getListDepartment(block_id) {
            this.show = true;
            this.data.department_id = '';
            this.data.part_id = '';
            this.data.khuvuc_id = '';
            this.data.chi_nhanh_id = '';
            this.data.parent_id = '';
            this.data.account_type_id = '';
            this.data.start_to_work = '';
            if (block_id != this.dkd) {
                this.checkShowDepartment = true;
            } else  {
                this.checkShowDepartment = false;
            }
            this.$store.dispatch(GET_DEPARTMENT,{parent_id: block_id}).then((res) => {
                if (!res.error) {
                    this.departments = res.data
                    this.getAccountType(block_id);
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getListPart(department_id, type) {
            if (type) {
                this.data.part_id = '';
            }
            this.$store.dispatch(GET_DEPARTMENT,{parent_id: department_id, type_block_part: true}).then((res) => {
                if (!res.error) {
                    this.part = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        updateStatePositions($event) {
            this.data.positions = []
            $event.map((value) => {
                this.data.positions.push(value.id)
            })
        },
        handleAccountType(id) {
            this.updateShowSelectBoss(id);
            this.checkShowCenterAndBranch(id);
            this.checkShowBusinessLevel(id);
            this.checkShowMechanism();
            this.changeMechanisms();
        },
        updateShowSelectBoss(id) {
            if (id === SALE_LEADER || id === SALE_EXPERT || id === SALE_MEMBER || id === SALE_INTERN) {
                this.showSelectBoss = true
            } else {
                this.showSelectBoss = false
            }
        },
        checkShowCenterAndBranch(id) {
            const qlhvAndGdvp = [SALE_LEADER, SALE_EXPERT, SALE_MEMBER, SALE_INTERN, GDKV, QLHV]
            const centerAndBranch = [CENTER_ASSIGN, BRANCH]
            //12: QLHV, 5: GDVP
            qlhvAndGdvp.includes(id) ? this.is_show_branch = true : this.is_show_branch = false
            centerAndBranch.includes(id) ? this.showTrungTam = true : this.showTrungTam = false
        },
        checkShowBusinessLevel(id) {
            this.checkShow = ![BOD, STAFF, TEAMLEADER].includes(id);
            this.getBusinessLevels();
        },
        getBusinessLevels() {
            this.$store.dispatch(GET_BUSINESS_LEVEL, {
                limit: 10000,
                status: 1,
                account_type_id: this.data.account_type_id
            }).then((res) => {
                if (!res.error) {
                    this.businessLevels = res.data.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        checkShowMechanism() {
            this.is_show_mechanism = [SALE_MEMBER, SALE_INTERN].includes(this.data.account_type_id)
        },
        updateStateKhuvuc() {
            this.data.salary_mechanism_id = '';
            this.data.chi_nhanh_id = '';
            this.branches = [];
            this.getBranches();
            this.changeMechanisms();
        },
        getBlock() {
            this.$store.dispatch(GET_ROLE_DEPARTMENT_BY_TYPE, {type: BLOCK}).then((res) => {
                if (!res.error) {
                    this.listBlock = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getPositions() {
            this.$store.dispatch(GET_POSITIONS, {}).then((res) => {
                if (!res.error) {
                    this.positions = res.data
                }
            });
        },
        getBranches() {
            this.$store.dispatch(GET_BRANCHES, {
                limit: 10000,
                status: 1,
                area_id: this.data.khuvuc_id,
            }).then((res) => {
                if (!res.error) {
                    this.branches = res.data.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        searchUserByBossCode(query) {
            this.search_loading = true;
            if (query.length > 0) {
                this.$store.dispatch(SEARCH_BY_MA_CAP_TREN, {
                    search_boss: query,
                    is_staff: 1,
                    limit: 100,
                }).then((res) => {
                    if (!res.error) {
                        this.users = res.data.data;
                        this.search_loading = false;
                    }
                }).catch(() => {
                    this.search_loading = false;
                });
            } else {
                this.search_loading = false;
            }
        },
        checkCaption() {
          this.$emit('checkCaption', this.index, this.data)
        },
        async getAreas() {
            await this.$store.dispatch(GET_AREAS, {
              limit: 10000,
            }).then((res) => {
              if (!res.error) {
                this.areas = res.data.data
              }
            });
        },
        removeForm() {
            this.$emit('removeForm', this.index)
        },
        changeMechanisms() {
            let area = this.areas.find((d) => d.id == this.data.khuvuc_id);
            let account_type = this.data.account_type_id;

            if (account_type == SALE_LEADER)
            {
                if (area.type_id == 1) {
                    this.mechanisms = [
                        {
                          id: 2,
                          name: 'Lương mềm'
                        }
                    ];
                    return;
                }
                this.mechanisms = mechanisms2;
                return;
            }
            if (area.type_id == 1) {
                this.mechanisms = mechanisms2;
                return;
            }
            this.mechanisms = this.listMechanisms;
        },
        changeParent() {
          this.data.parent_id = this?.parentName;
        },
        getMechanisms() {
            let area = this.data.area;
            let account_type = this.data.account_type_id;

            if (account_type == SALE_LEADER)
            {
                if (area && area.type_id == 1) {
                    this.mechanisms = [
                        {
                            id: 2,
                            name: 'Lương mềm'
                        }
                    ];
                    return;
                }
                this.mechanisms = mechanisms2;
                return;
            }
            if (area && area.type_id == 1) {
                this.mechanisms = mechanisms2;
                return;
            }
            this.mechanisms = this.listMechanisms;
        },
        parentHandle() {
            if(this.parent) {
                this.parentName = this.parent.name ?? '';
            }
        }
    }
}
</script>

<style scoped>
  .grid {
      display: grid;
  }
</style>